const HEIGHT_SIZE = 5;
const r = (n) => Math.round(1000 * n) / 1000;
const ranges = {
  week: 7,
  month: 28,
  quarter: 28 * 3,
  year: 365,
};
const divs = {
  week: 7,
  month: 14,
  quarter: 12,
  year: 12,
};

// Create a class for the element
class DayGraph extends HTMLElement {
  static observedAttributes = ["values"];
  range = "week";

  connectedCallback() {
    const shadow = this.attachShadow({ mode: "open" });

    this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

    // Create some CSS to apply to the shadow dom
    const style = document.createElement("style");
    const styles = { text: "#aaa6", textHover: "#aaa9" };
    style.textContent = `
    	:host {
      	position: relative;
        display: block;
      }
      
      :host::before {
      	box-sizing: border-box;
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        z-index: 2;
        border: 2px solid #fff;
        transition: all 0.2s ease;
        border-radius: inherit;
        pointer-events: none;
      }
    
      div {
      	position: absolute;
        z-index: 1;
        top: 3px;
        display: flex;
        justify-content: center;
        gap: 5px;
        width: 100%;
      }
      
      button {
      	opacity: 0.6;
      	background: ${styles.text};
        border: 1px solid #aaa;
				border-radius: 4px;
        color: inherit;
        transition: all .2s ease;
        cursor: pointer;
      }
    
      svg {
				width: 100%;
        transform-origin: center center;
        transform: scale(1,-1);
        display: block;
        margin: 0;
      }

      circle:hover ~ rect,
      circle:hover ~ text {
        opacity: 1;
        transition: all 0.2s ease 0s;
      }
      
      path {
        stroke: #aaa;
        stroke-width: 2;
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
      }
      
      pattern path {
	      stroke: ${styles.text};
 				stroke-width: 2;
        transition: all 0.2s ease;
      }
      
      text {
	      fill: #aaa;
	      transform-origin: center center;
      	transform: scale(1, -1);
        font-family: Sans-Serif;
        transition: all .2s ease;
        font-size: 14px;
      }
      
      /* Hover states */
      :host(:hover) pattern path {
	      stroke: ${styles.textHover};
      }
      
      :host(:hover) button {
	      opacity: 0.7;
      }
     
      :host(:hover) button:hover {
	      opacity: 1;
      }

      .active,
      :host(:hover) .active {
        opacity: 1;
      }
    `;

    const toggle = document.createElement("div");
    toggle.innerHTML = `
      <button name="week" class="active">Week</button>
      <button name="month">Month</button>
      <button name="quarter">Quarter</button>
      <button name="year">Year</button>
    `;
    toggle.querySelectorAll("button").forEach((b) => {
      b.addEventListener("click", (e) => {
        toggle.querySelector(".active")?.classList?.remove("active");
        e.currentTarget.classList.add("active");
        this.range = e.currentTarget.name;
        this.render();
      });
    });

    // Attach the created elements to the shadow dom
    shadow.appendChild(style);
    shadow.appendChild(toggle);
    shadow.appendChild(this.svg);

    this.size = this.getBoundingClientRect();
    this.size.width = Math.round(this.size.width);
    this.size.height = Math.round(this.size.height);

    this.render();
  }

  getLength() {
    return ranges[this.range];
  }

  getBounds() {
    const points = this.values.slice(-ranges[this.range]);

    const maxVal = Math.max(...points.filter(Boolean));
    const minVal = Math.min(...points.filter(Boolean));

    // Add a buffer around the numbers
    const max = Math.ceil((maxVal + 1) / HEIGHT_SIZE) * HEIGHT_SIZE;
    const min = Math.floor((minVal - 1) / HEIGHT_SIZE) * HEIGHT_SIZE;

    const width = r(this.size.width / divs[this.range]);
    const height = r((this.size.height * HEIGHT_SIZE) / (max - min));

    return { max, min, width, height };
  }

  getPoints() {
    const size = this.getLength();
    const { max, min } = this.getBounds();
    let last = null;
    return this.values
      .map((y, i) => {
        if (!y) return undefined;
        if (!last) last = y;
        const pos = y <= last;
        last = y;
        const index =
          this.values.length === size ? i : i + (size - this.values.length);
        const x = r((this.size.width * index) / size);
        y = r(((y - min) * this.size.height) / (max - min));
        return { pos, x, y };
      })
      .filter(Boolean);
  }

  renderGrid() {
    if (!this.values.filter(Boolean).length) return "";
    const { max, min, width, height } = this.getBounds();
    return `
    	<defs>
        <pattern id="grid" width="${width}" height="${height}" patternUnits="userSpaceOnUse">
          <path d="M ${width} 0 L 0 0 0 ${height}"/>
        </pattern>
      </defs>

      <rect width="200%" height="200%" fill="url(#grid)" />
      <text x="5" y="15">${max}</text>
      <text x="5" y="${this.size.height - 6}">${min}</text>
    `;
  }

  renderPath() {
    const points = this.getPoints();
    if (!points.length) return "";
    let path = `M${points[0].x} ${points[0].y} `;
    path += points
      .slice(1)
      .map((p) => `L${p.x} ${p.y}`)
      .join(" ");

    return `<path d="${path}" />`;
  }

  render() {
    const grid = this.renderGrid();
    const path = this.renderPath();
    const points = this.getPoints()
      .map(
        (p) =>
          `<circle cx="${p.x}" cy="${p.y}" r="5" fill="${
            p.pos ? "green" : "red"
          }" />`
      )
      .join("\n");

    this.svg.innerHTML = `
    	${grid}
      ${path}
      ${points}
    `;
  }

  disconnectedCallback() {
    delete this.svg;
  }

  adoptedCallback() {
    console.log("Custom element moved to new page.");
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "values") {
      this.values = newValue
        .split(/,\s*/)
        .map(Number)
        .map((p) => Math.round(p * 10) / 10);
    }

    // It has already been rendered at least once
    if (this.svg) {
      this.render();
    }
  }
}

customElements.define("day-graph", DayGraph);
