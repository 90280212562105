import dayjs from "dayjs";
import Store, { useSelector, useStore } from "statux";
import styled, { createGlobalStyle } from "styled-components";

const range = (num) => [...Array(num).keys()];

const PERIOD = 365;

const Style = createGlobalStyle`
  * {
    box-sizing: inherit;
  }
  body {
    background: black;
    color: #fff;
    font-family: 'Mooli', sans-serif;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const ListWrap = styled.div`
  position: relative;
`;

const Item = styled.div`
  padding: 0;
  border-bottom: 1px solid #aaa;
  position: relative;
  display: flex;
  justify-content: space-between;
`;
const Day = styled.div`
  background: #333;
  padding: 2px 0;
  width: 40px;
  height: 24px;
  text-align: center;
`;

const Input = styled.input`
  width: 90px;
  background: inherit;
  color: inherit;
  font: inherit;
  font-size: 20px;
  border: none;
  border-left: 1px solid #444;
  padding: 10px 15px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const DisplayDiff = styled.div`
  color: ${(p) => (p.$sign > 0 ? "red" : p.$sign < 0 ? "#0b0" : "#aaa")};
  background: ${(p) => (p.$sign > 0 ? "#300" : p.$sign < 0 ? "#030" : "#222")};
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
`;

const Month = styled.div`
  // margin-top: 10px;
  padding: 5px 10px 5px 8px;
  background: #666;
  display: inline-block;

  & + ${Item} {
    border-top: 1px solid #aaa;
  }
`;

const Evolution = () => {
  const [days] = useStore("days");
  const iso = (i) => dayjs().subtract(i, "day").format("YYYY-MM-DD");
  const data = range(PERIOD)
    .map((i) => days[iso(i)]?.weight)
    .reverse();

  return <day-graph values={data.join(",")} />;
};

const Diff = ({ day }) => {
  const [data = {}] = useStore(`days.${day}`);
  const prevKey = dayjs(day).subtract(1, "day").format("YYYY-MM-DD");
  const [prev = {}] = useStore(`days.${prevKey}`);

  if (!data?.weight || !prev?.weight || data?.weight === prev?.weight) {
    return <DisplayDiff $sign={0}>-</DisplayDiff>;
  }
  const diff = Number(data.weight) - Number(prev?.weight);
  return (
    <DisplayDiff $sign={diff}>
      {diff > 0 ? "+" : ""}
      {diff.toFixed(1)}
    </DisplayDiff>
  );
};

const Weight = ({ day }) => {
  const [data = {}, setData] = useStore(`days.${day}`);

  const onWeightChange = (e) => {
    setData((data) => ({
      ...data,
      weight: Number(e.target.value) || 0,
    }));
  };

  return (
    <Input
      type="number"
      placeholder="-"
      value={data.weight || ""}
      onChange={onWeightChange}
    />
  );
};

const Picker = styled.div`
  display: flex;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
`;

const Button = styled.button`
  font: inherit;
  font-size: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  background: #000;
  padding: 0 5px;
  border: none;
  border-left: 1px solid #666;
  opacity: 0.4;
  cursor: pointer;
  transition: all 0.2s ease;

  ${(p) => p.$active && `opacity: 1; background: #333;`}

  :hover {
    opacity: 0.8;
    ${(p) => p.$active && `opacity: 1;`}
  }

  &:last-child {
    border-right: 1px solid #666;
  }
`;

const Pick = ({ day, type, icon }) => {
  const [data = {}, setData] = useStore(`days.${day}`);
  const onClick = () => {
    setData((data) => ({ ...data, type }));
  };
  const active = data?.type === type;
  return (
    <Button $active={active} onClick={onClick}>
      {icon}
    </Button>
  );
};

const Head = styled.div`
  width: 40px;
`;

const Entry = ({ diff = 0 } = {}) => {
  const date = dayjs().subtract(diff, "day");
  const day = date.format("YYYY-MM-DD");
  return (
    <>
      {date.format("DD") === "01" && (
        <Month>{date.subtract(1, "day").format("MMMM")}</Month>
      )}
      <Item>
        <Head>
          <Day>{date.format("DD")}</Day>
          <Diff day={day} />
        </Head>
        <div style={{ flexGrow: 1 }} />
        <Picker>
          <Pick day={day} type="fast" icon="🍽️" />
          <Pick day={day} type="salt" icon="🧂" />
          <Pick day={day} type="keto" icon="🥩" />
          <Pick day={day} type="food" icon="🍔" />
        </Picker>
        <Weight day={day} />
      </Item>
    </>
  );
};

// Define the initial state as an object:
const days = JSON.parse(localStorage.days || "{}");

// Listen for changes on the state and save it in localStorage:
const LocalStorage = () => {
  const days = useSelector("days");
  localStorage.days = JSON.stringify(days);
  return null;
};

const Download = () => {
  const days = useSelector("days");
  const download = () => {
    var data = new Blob([JSON.stringify(days, null, 2)], {
      type: "application/json",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.setAttribute("download", "gym-planner.json");
    link.click();
  };
  return <button onClick={download}>Download All</button>;
};

export default function App() {
  return (
    <Store days={days} size={7}>
      <LocalStorage />
      <Style />
      <Evolution />
      <Download />
      <ListWrap>
        <Month>{dayjs().subtract(1, "day").format("MMMM")}</Month>
        {range(PERIOD).map((i) => (
          <Entry diff={i} />
        ))}
      </ListWrap>
    </Store>
  );
}
